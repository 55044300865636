import { Card, Grid, styled, useTheme, Box, Button, Menu, MenuItem, LinearProgress, TextField, Icon, IconButton, CircularProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';



const chunk = (arr, len) => {
    var chunks = [],
        i = 0,
        n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}

const NumberButton = styled(Button)(() => ({
    fontSize: '26px'
}))



const Analytics = ({ onRefresh, min = 68, max = 268, hidePagination, content2, renderItem, showPrice, hideCopy, isLoading, dataArr, pageSize = 15, unmask, unmaskValue, handleClick, mask = 'yyyyyyyy', displaySource, hideAuto, hideDl }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette } = useTheme();
    const [masterData, setMasterData] = useState(dataArr)
    const [data, setData] = useState([])
    const [special, setSpecial] = useState([])
    const [completed, setCompleted] = useState(0)
    const [page, setPage] = useState(0)
    const [auto, setAuto] = useState(false)
    const [changingInterval, setChangingInterval] = useState(5)
    const [timePassed, setTimePassed] = useState(0)
    const [price, setPrice] = useState(88)
    const [refresh, setRefresh] = useState(0)
    const offsetPage = (val) => {
        setPage(page + val)
    }


    useEffect(() => {
        onRefresh && onRefresh(refresh)
    }, [refresh])


    const unmaskFunc = (orignal, v) => {
        if (v) {
            const index = orignal.indexOf(v)
            return v.padStart(index + v.length, 'X') + [...Array(orignal.length - (index + v.length)).keys()].map(e => 'X').join('')

        } else {
            return 'XXXX' + orignal.slice(4)
        }

    }

    const download = (filename, text) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    const formatData = (data) => {
        console.log(data)
        return data.map(e => e.num.slice(0, 4) + ' ' + e.num.slice(4) + ' Price').join('\r\n')
    }


    const loadModal = (data, price) => {

    }

    useEffect(() => {
        setData(chunk(dataArr, pageSize))
        setPage(0)
    }, [dataArr, pageSize])

    useEffect(() => {
        function progress() {
            setTimePassed(e => {
                const _timepassed = e + 1
                console.log(_timepassed)

                return _timepassed
            })


        }

        if (auto) {
            const timer = setInterval(progress, 1000)
            return () => {
                clearInterval(timer)
            }
        } else {
            setTimePassed(0)
        }

    }, [data, auto])

    useEffect(() => {

        if (timePassed % changingInterval === 0) {
            setPage((p) => {
                if (p < data.length - 1) {
                    return p + 1
                } else {
                    return 0
                }
            })
        }

    }, [timePassed])

    const map = {
        'Gomo': 'directions',
        'Starhub': 'star',
        'Singtel': 'music_note',
        'M1': 'brightness_7',
        'MyRepublic': 'domain',
        'Simba': 'title',
        'singtel-changeNumber': 'music_note'
    }
    return (
        <Fragment>
            <Box display="flex" width="100%" flexDirection="column" alignItems={"center"} alignContent={"center"}>

                {
                    content2
                }

            </Box>

            <Box gap="30px" display="flex" flexWrap="wrap" alignItems={"center"} alignContent={"center"} justifyContent={"center"}>


                {
                    data.map((chunks, index) => {
                        return index === page && chunks.map((n => renderItem && renderItem(n)))
                    })
                }
            </Box>



            <Box display="flex" flexWrap="wrap" alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                {
                    isLoading ? <>
                        <CircularProgress />
                    </> : <Box height="40px"></Box>
                }
            </Box>

            {
                !hidePagination && <>
                    <Box display="flex" flexWrap="wrap" alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                        <NumberButton
                            variant="outlined"
                            aria-haspopup="true"
                            onClick={() => offsetPage(-1)}
                            disabled={page === 0}
                        >Prev</NumberButton>
                        {
                            `Page ${data.length ? page + 1 : 0} of ${data.length}`
                        }
                        <NumberButton
                            variant="outlined"
                            aria-haspopup="true"
                            onClick={() => offsetPage(1)}
                            disabled={page >= data.length - 1}
                        >Next</NumberButton>

                        {
                            !hideAuto && <NumberButton
                                variant="outlined"
                                aria-haspopup="true"
                                onClick={() => setAuto(!auto)}

                            > {auto ? `${timePassed % changingInterval} sec` : "Auto"}</NumberButton>
                        }
                        {
                            !hideDl && <NumberButton
                                variant="outlined"
                                aria-haspopup="true"
                                onClick={() => {
                                    setRefresh(e => e + 1)
                                }}

                            > Refresh Price</NumberButton>
                        }
                        {
                            !hideDl && <NumberButton
                                variant="outlined"
                                aria-haspopup="true"
                                onClick={() => {
                                    loadModal(data, price)
                                }}

                            > Download</NumberButton>
                        }

                    </Box>
                    <Box display="flex" flexWrap="wrap" alignItems={"center"} alignContent={"center"} justifyContent={"center"}>
                        <h5> {dataArr.length} result found</h5>
                    </Box>
                </>
            }
            <br />
            <br />
        </Fragment >
    );
};

export default Analytics;
