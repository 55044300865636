import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import NumberConsole from "../components/NumberConsole"
import Button from '@mui/material/Button';
import MobileNumber from "../components/MobileNumber"
import Parse from '../vendor/Parse'
import CircularProgress from '@mui/material/CircularProgress';
import ThreeCol from '../components/ThreeColLayout'
const { useEffect } = React;
//eeee

const quotes = [
  "Biggest Database in Singapore",
  "We Update our database Frequently",
  "Each Number Found is Each Number Less",
]

const channelUrl = 'https://whatsapp.com/channel/0029Vakmj7PDOQIVXVhcRJ14'


const IndexPage = () => {


  const [value, setValue] = React.useState(
    dayjs('1988-09-14T12:00:01'),
  );
  const [number, setNumber] = useState('')
  const [day, setDate] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [elements, setElements] = useState(null)
  const [accordion, setAccordion] = useState([])
  const [result, setResult] = useState([])
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const handleChange = (newValue) => {
    console.log(newValue)
    setValue(newValue);
  };


  const searchNumbers = async (query) => {
    return Parse.Cloud.run('searchPublicNumbers', {
      query: query,
      itemKey: 'Numbers'
    })
  }
  const [isLoading, setIsLoading] = useState(false)
  const [currentQuote, setCurrentQuote] = useState(quotes[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 5000); // Change quote every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrentQuote(quotes[index]);
  }, [index]);

  return (
    <>
      <Seo title="Home" />

      <Box display="flex" justifyContent="center" flexDirection="column">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <h2> {currentQuote} </h2>
          <Box display="flex" justifyContent="center" flexDirection="row">
            <TextField id="outlined-basic"
              value={number}
              label="Type 3 - 5 Digits"
              placeholder="Type 3 - 5 Digits"
              variant="outlined"
              onChange={e => {
                if (e.target.value.length < 6) {
                  setNumber(e.target.value)
                }

              }}
            />
            <Button
              onClick={async () => {
                setIsLoading(true)
                const _result = await searchNumbers(number)
                setResult(_result)
                setIsLoading(false)
              }}
            >Find</Button>
          </Box>

        </LocalizationProvider>

      </Box>
      <Box display={"flex"} flexDirection="column" alignItems={"center"} justifyContent={"center"}>
        <Box display={"flex"} flexDirection="column" alignItems={"center"} justifyContent={"center"}>

          {
            isLoading && <Box padding="16px"><CircularProgress /></Box>
          }


          <Box textAlign="center" >
            <Box style={{ fontSize: '2rem', marginTop: '16px' }}>
              {
                !isLoading && <span style={{ color: result.length ? '#2e7d32' : '#f44336' }}> {`${result.length ? 'Have result' : 'No result'}`} </span>
              }
            </Box>
            <div style={{ fontSize: '1.5rem' }}>
              {
                !isLoading && !!result.length && <>
                  <a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159&text=Find Mobile Number`}>whatsapp</a> us to know the number and how to get it.</>
              }
            </div>
          </Box>
        </Box>
        <h1>For Best Result</h1>
        <ol>
          <li>
            Best to check in the morning daily. Fastest Finger First.
          </li>
          <li>
            <a style={{ color: '#25D366', textDecoration: 'none' }} href="https://whatsapp.com/channel/0029VaiSp2l2ER6py4tniX1Q">Join</a> our Channel to know more tips and updates.
          </li>
          <li>
            Check your number at mobile413.com
          </li>
          <li>
            If it shows there are results for your number.
          </li>
          <li>
            <a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159`}>whatsapp</a> us to know how to get it.
          </li>
        </ol>

      </Box >
    </>
  )
}

export default IndexPage
