import { Card, Grid, styled, useTheme, Box, Button, Menu, MenuItem, LinearProgress, TextField, Icon, IconButton, CircularProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Console from './Console'

const chunk = (arr, len) => {
    var chunks = [],
        i = 0,
        n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}

const NumberButton = styled(Button)(() => ({
    fontSize: '26px'
}))



const Analytics = ({ min, max, crazySale, showPrice, hideCopy, isLoading, dataArr, pageSize, unmask, unmaskValue, handleClick, mask = 'yyyyyyyy', displaySource, hideAuto, hideDl, ...props }) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette } = useTheme();
    const [masterData, setMasterData] = useState([])
    const [data, setData] = useState([])
    const [special, setSpecial] = useState([])
    const [completed, setCompleted] = useState(0)
    const [page, setPage] = useState(0)
    const [auto, setAuto] = useState(false)
    const [changingInterval, setChangingInterval] = useState(5)
    const [timePassed, setTimePassed] = useState(0)
    const [price, setPrice] = useState(88)

    const randomPrice = (min, difference) => {
        const random = Math.floor(Math.random() * max) + min;
        return `$${random}`
    }


    const download = (filename, text) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    const generateData = (dataArr) => {
        return dataArr && dataArr.map ? dataArr.map(e => ({
            ...e,
            crazyPrice: '$' + randomPrice(Number(min), Number(max))
        })) : []

    }

    useEffect(() => {
        setMasterData(generateData(dataArr))
    }, [dataArr])
    const map = {
        'Gomo': 'directions',
        'Starhub': 'star',
        'Singtel': 'music_note',
        'M1': 'brightness_7',
        'MyRepublic': 'domain',
        'Simba': 'title',
        'singtel-changeNumber': 'music_note'
    }
    return (
        <Fragment>

            <Box>
                <Console
                    hideDl={hideDl}
                    hideAuto={!crazySale}
                    {...props}
                    pageSize={pageSize}
                    isLoading={isLoading}
                    dataArr={masterData}
                    onRefresh={() => {
                        setMasterData(generateData(dataArr))
                    }}
                
                    renderItem={(n) => <NumberButton
                        title={n?.title}
                        variant={n.active ? "contained" : "outlined"}
                        aria-haspopup="true"
                        onClick={() => handleClick && handleClick(n)}
                    >

                        <Box >
                            {
                                mask
                                    .padEnd(n.num.length, 'y')
                                    .split('')
                                    .map((m, i) => m === 'x' ? 'X' : n.num[i])
                            }
                            {
                                "" && <Box display="inline" color="red"> {n.crazyPrice} </Box>
                            }
                            {
                                displaySource && <Icon style={{ fontSize: '14px' }}>{map[n.sourceId]}</Icon>
                            }
                            {
                                !hideCopy && <IconButton

                                    onClick={
                                        (e) => {
                                            e.stopPropagation()
                                            navigator.clipboard.writeText(n.num);
                                        }
                                    }

                                ><Icon>content_copy</Icon></IconButton>
                            }
                        </Box>
                    </NumberButton>}


                />

            </Box>
        </Fragment >
    );
};

export default Analytics;
